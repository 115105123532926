.container {
    display: grid;
    grid-template-rows: 152px 1fr 152px;
    grid-template-columns: 1fr;
    height: 100vh;
    overflow-x: hidden;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.011em;
    text-align: center;
}

.main p {
    color: #fff;
    max-width: 572px;
}
