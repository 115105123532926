@import url("https://use.typekit.net/hni0uuh.css");

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    font-family: "sofia-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    background: url("./static/click-to-leap-bkgd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
}

body :not(a) {
    cursor: alias;
}
