
.grid {
    --gap: 15px;
    --item-width: 250px;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--item-width), 1fr));
    gap: var(--gap);
    /*max-width: calc(var(--item-width) * 3 + (var(--gap) * 3));*/
    margin: 0 auto;
    padding: 2rem 42px;
    width: calc(100% - (42px * 2));
}

.grid > video,
.grid > img {
    height: 100%;
    width: 100%;
    max-height: 350px;
    object-fit: cover;
}

.modal {
    border-radius: 15px;
    overflow: hidden;
    padding: 0 !important;
    min-height: 400px;
    max-width: 1400px !important;
    width: 75vw;
}

.modalInner {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 2fr;
    min-height: 400px;
    margin: 0;
    padding: 40px 30px;
}

.modal video,
.modal img {
    align-self: center;
    object-fit: cover;
    max-width: 100%;
}

.image {
    display: flex;
}

.modalContent {
    align-content: center;
    font-size: 32px;
    padding: 1rem;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    padding-left: 0;
    flex-wrap: wrap;
    list-style-type: none;
}

.tags li {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.011em;
}
