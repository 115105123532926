.figure {
    --padding-v: 15px;
    --padding-h: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 24px;
    background: #f5f5f5;
    padding: var(--padding-v) var(--padding-h);
    margin: 0;
    position: relative;
    max-width: calc(95vw - var(--padding-h) * 2);
    width: calc(100% - var(--padding-h) * 2);
    z-index: 5;
}

.figure:hover {
    cursor: initial !important;
}

.figure > :first-child {
    flex: 1;
}

.figcaption {
    position: relative;
    z-index: 2;
    text-align: left;
}

.figure .figcaption p {
    color: #000;
    font-weight: 500;
    letter-spacing: -0.01em;
}

.tags {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    padding-left: 0;
    flex-wrap: wrap;
    list-style-type: none;
}

.tags li {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.011em;
}

.img {
    display: flex;
    position: relative;
    flex: 2;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    z-index: 5;
    mix-blend-mode: multiply;
    width: 100%;
    max-width: 95vw;
    overflow: hidden;
    min-height: 366px;
    max-height: 400px;
}

.figure img:hover,
.figure video:hover {
    cursor: initial !important;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (width > 810px) {
    .figure {
        --padding-v: 40px;
        --padding-h: 30px;
        flex-direction: row;
        width: auto;
    }

    .figcaption p {
        font-size: 20px;
        line-height: 24px;
    }

    .tags li {
        font-size: 16px;
        line-height: 24px;
    }

    .img {
        max-width: 648px;
    }

    .animating img {
        width: 648px;
        height: 400px;
    }
}

@media (width > 1260px) {
    .figure {
        flex-direction: row;
        width: auto;
    }

    .figcaption p {
        font-size: clamp(16px, 1.25vw, 32px);
        line-height: 36px;
    }

    .tags li {
        font-size: 16px;
        line-height: 24px;
    }
}
