.navbar {
    align-items: center;
    background: linear-gradient(175.98deg, #000000 3.28%, rgba(0, 0, 0, 0.1) 96.72%);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px 64px;
}

.logo {
    width: 161px;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.center {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin: 0 auto;
}

.switches {
    background: #000;
    border: 1px solid #333;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 10px;
}

.switch {
    background: transparent;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 25px;
    gap: 5px;
    border: none;
}

.switch:hover {
    cursor: pointer;
}

.switch.active {
    background: #333;
    border-radius: 10px;
    font-weight: bold;
}


.rotating {
    animation: rotating 0.25s linear infinite;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mlmm {
    display: none;
    width: 87px;
}

@media (width > 810px) {
    .mlmm {
        display: block;
        width: 112px;
    }

    .navbar {
        flex-direction: row;
        justify-content: space-between;
    }
}
