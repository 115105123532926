.footer {
    align-items: center;
    background: linear-gradient(175.98deg, rgba(0, 0, 0, 0.1) 3.28%, #000000 96.72%);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 64px;
}

.left a {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.011em;
    text-decoration: none;
}

.left a img {
    width: 19px;
}

.socialList {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style-type: none;
}

.socialItem a {
    color: #fff;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.011em;
    text-decoration: none;
}

.socialItem a:hover {
    text-decoration: underline;
}

.directions {
    text-align: center;
}

.directions p {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
}

@media (width > 810px) {
    .directions p {
        font-size: 16px;
        line-height: 24px;
    }
}

.audioToggle {
    all: unset;
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.audioToggle:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (width > 810px) {
    .left a,
    .socialItem a {
        font-size: 16px;
        line-height: 16px;
    }

    .socialList {
        gap: 15px;
    }

    .left a img {
        width: 26px;
    }
}
